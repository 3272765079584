import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { htmlSerializer } from 'src/utils';
import { Button } from 'src/components/common/atoms';
import { StoreListPrimaryFragment } from 'graphql-types';

type Props = StoreListPrimaryFragment & {
  className?: string;
  current: boolean;
  index: number;
  onClick: (index: number) => void;
  targetId: string;
};

export const fragment = graphql`
  fragment StoreListPrimary on PRISMIC_StoresBodyStorePrimary {
    store_title
    store_content
  }
`;

export const ListItem: React.FC<Props> = ({
  className,
  current,
  index,
  onClick,
  targetId,
  store_title,
  store_content,
}: Props) => {
  const onClickSelf = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => {
    onClick(index);
  }, [onClick, index]);
  return (
    <li className={className || ''}>
      <StyledButton
        className={current ? 'current' : ''}
        onClick={onClickSelf}
        aria-controls={targetId}
        aria-label={store_title ? 'Select ${store_title} image' : ''}
      >
        {store_title && <div>{RichText.asText(store_title)}</div>}
        {store_content && (
          <RichText render={store_content} htmlSerializer={htmlSerializer} />
        )}
      </StyledButton>
    </li>
  );
};

const StyledButton = styled(Button)`
  border: none;
  text-align: left;
  &.current,
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    outline: none;
  }
`;

export default ListItem;
