import * as React from 'react';
import styled from 'styled-components';
import { ListItem } from './list-item';
import { StoreListPrimaryFragment } from 'graphql-types';

type Props = {
  className?: string;
  list: StoreListPrimaryFragment[];
  currentImagesIndex: number;
  onClick: (index: number) => void;
  targetId: string;
};

export const StoreList: React.FC<Props> = ({
  className,
  list,
  currentImagesIndex,
  onClick,
  targetId,
}: Props) => (
  <Wrapper className={className || ''}>
    {list.map((store, index) => (
      <StyledListItem
        key={`store-list-item-${index}`}
        current={currentImagesIndex === index}
        index={index}
        onClick={onClick}
        targetId={targetId}
        {...store}
      />
    ))}
  </Wrapper>
);

const StyledListItem = styled(ListItem)`
  & + & {
    margin-top: ${({ theme }) => theme.structure.margin.medium}px;
  }
`;

const Wrapper = styled.ul``;

export default StoreList;
