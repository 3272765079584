import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Modal from 'react-modal';
import { useIsMobile } from 'src/utils';
import {
  ContentColumn,
  PageHeader,
  ModalContent,
  MenuButton,
} from 'src/components/common';
import { StoreList } from './store-list';
import { Images } from './images';
import { StoresContentFragment } from 'graphql-types';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby');

type Props = StoresContentFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment StoresContent on PRISMIC {
    stores(lang: $lang, uid: "stores") {
      title
      body {
        ... on PRISMIC_StoresBodyStore {
          primary {
            ...StoreListPrimary
          }
          fields {
            ...StoreImage
          }
        }
      }
    }
  }
`;

function useContents({ stores }: { stores: StoresContentFragment['stores'] }) {
  const list = stores?.body
    ? stores.body.map(({ primary }) => (primary ? { ...primary } : {}))
    : [];
  const listImages = stores?.body
    ? stores.body.map(({ fields }) => fields)
    : [];
  return {
    list,
    listImages,
  };
}

type UseCurrentImagesIndexProps = {
  isMobile: boolean;
};
const useCurrentImagesIndex = ({ isMobile }: UseCurrentImagesIndexProps) => {
  const [currentImagesIndex, setCurrentImagesIndex] = React.useState(
    isMobile ? -1 : 0
  );
  const handleClickStore = React.useCallback((index: number) => {
    setCurrentImagesIndex(index);
  }, []);
  const handleClickClose = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => {
    setCurrentImagesIndex(-1);
  }, []);
  return { currentImagesIndex, handleClickStore, handleClickClose };
};

export const Content: React.FC<Props> = ({ className, stores }: Props) => {
  const imagesId = 'store-images';
  const isMobile = useIsMobile();
  const { list, listImages } = useContents({ stores });

  const {
    currentImagesIndex,
    handleClickStore,
    handleClickClose,
  } = useCurrentImagesIndex({ isMobile });

  const images =
    currentImagesIndex >= 0 ? listImages[currentImagesIndex] : null;

  const title = stores?.title ? RichText.asText(stores.title) : '';
  return (
    <div className={className || ''} role="region">
      <ContentColumn
        items={[
          {
            component: <PageHeader title={title} isSingle={false} />,
            order: 1,
          },
          {
            component: (
              <StoreList
                list={list}
                currentImagesIndex={currentImagesIndex}
                onClick={handleClickStore}
                targetId={imagesId}
              />
            ),
            order: 2,
          },
          {
            component: images ? (
              isMobile ? (
                <Modal
                  isOpen={images !== null}
                  onRequestClose={handleClickClose}
                  style={{
                    overlay: {
                      zIndex: 10,
                    },
                    content: {
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      border: 'none',
                      borderRadius: 'none',
                      padding: 0,
                    },
                  }}
                  contentLabel="Store images"
                >
                  <ModalContent
                    mobileTitle={title}
                    closeButton={
                      <MenuButton
                        onClick={handleClickClose}
                        isPressed={true}
                        ariaLabel="Close button"
                        targetId={imagesId}
                      />
                    }
                  >
                    <Images images={images} id={imagesId} hasMargin={true} />
                  </ModalContent>
                </Modal>
              ) : (
                <Images images={images} id={imagesId} hasMargin={false} />
              )
            ) : null,
            order: 3,
            hasMarginTop: false,
            hasMarginBottom: false,
          },
        ]}
      />
    </div>
  );
};

export default Content;
