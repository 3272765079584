import * as React from 'react';
import { graphql } from 'gatsby';
import { WithoutMargin } from 'src/components/common';
import { Img } from 'src/components/common/atoms';
import { StoreImageFragment } from 'graphql-types';

type ImagesList = { images: StoreImageFragment[] };

type Props = ImagesList & {
  className?: string;
  id: string;
  hasMargin?: boolean;
};

export const fragment = graphql`
  fragment StoreImage on PRISMIC_StoresBodyStoreFields {
    store_image
    store_imageSharp {
      id
      childImageSharp {
        fluid(maxWidth: 1000, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const ImagesList: React.FC<ImagesList> = ({ images }: ImagesList) => (
  <>
    {images.map(
      (image, index) =>
        image.store_imageSharp && (
          <Img
            key={`store-image-${index}`}
            fluid={image.store_imageSharp.childImageSharp?.fluid}
            alt={image.store_image?.alt || ''}
            sizes="(min-width: 1024px) calc(100vw / 3), 100vw"
          />
        )
    )}
  </>
);

export const Images: React.FC<Props> = ({
  className,
  images,
  id,
  hasMargin = false,
}: Props) => (
  <div id={id} className={className || ''} alia-live="polite">
    {hasMargin && <ImagesList images={images} />}
    {!hasMargin && (
      <WithoutMargin>
        <ImagesList images={images} />
      </WithoutMargin>
    )}
  </div>
);

export default Images;
