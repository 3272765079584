import * as React from 'react';
import { PageProps, graphql } from 'gatsby';
import { useSlug, useTranslationLink } from 'src/utils';
import { SEO } from 'src/components/common';
import { Content } from 'src/components/stores';
import { GetStoresQuery } from 'graphql-types';
import { DefaultPageContextProps } from 'custom-types';

type Props = PageProps<GetStoresQuery, DefaultPageContextProps>;

export const query = graphql`
  query GetStores($lang: String!) {
    site {
      siteMetadata {
        titleCustom {
          ja {
            stores
          }
          en {
            stores
          }
        }
        description {
          ja {
            stores
          }
          en {
            stores
          }
        }
      }
    }
    prismic {
      stores(lang: $lang, uid: "stores") {
        _meta {
          alternateLanguages {
            uid
            type
          }
        }
        title
      }
      ...StoresContent
    }
  }
`;

type UseSEOProps = Pick<DefaultPageContextProps, 'langKey'> & {
  site: GetStoresQuery['site'];
};
function useSEO({ site, langKey }: UseSEOProps) {
  return React.useMemo(() => {
    const title =
      langKey === 'ja'
        ? site?.siteMetadata?.titleCustom?.ja?.stores
        : site?.siteMetadata?.titleCustom?.en?.stores;
    const description =
      langKey === 'ja'
        ? site?.siteMetadata?.description?.ja?.stores
        : site?.siteMetadata?.description?.en?.stores;
    return {
      title,
      description,
    };
  }, [site, langKey]);
}

const Stores: React.FC<Props> = ({ data: { site, prismic } }: Props) => {
  const { slug, langKey } = useSlug();
  const seo = useSEO({ site, langKey });
  useTranslationLink({
    slug,
    langKey,
    uid: prismic.stores?._meta?.alternateLanguages?.[0]?.uid,
    type: prismic.stores?._meta?.alternateLanguages?.[0]?.type,
  });
  return (
    <>
      <SEO {...seo} />
      <Content {...prismic} />
    </>
  );
};

export default Stores;
